const cardsContainer = document.querySelector(".cards") as HTMLDivElement;

const questions = [
  "Mi volt a legnagyobb kockázat, amit valaha vállaltál?",
  "Question 2",
  "Question 3",
  "Question 4",
];
const colors = ["#ffee99", "#b5f3ff", "#f7c7ff", "#c0ff99"];

for (const [i, q] of questions.entries()) {
  const div = document.createElement("div");
  cardsContainer.append(div);
  div.style.backgroundColor = colors[i % colors.length];

  const p = document.createElement("p");
  div.append(p);

  p.textContent = q;
}

// cardsContainer.addEventListener("scroll", (e) => {
//   console.log(cardsContainer.scrollLeft);

//   if (cardsContainer.scrollLeft > 500) {
//     e.preventDefault();
//     // cardsContainer.sc
//     cardsContainer.style.display = "none";

//     setTimeout(() => {
//       cardsContainer.style.display = "inherit";
//     }, 5);
//   }
// });

let pointerDown = false;
let startX = 0;
let deltaX = 0;
let n = 0;

window.addEventListener("touchstart", (e) => {
  startX = e.touches[0].screenX;
  pointerDown = true;
});

window.addEventListener("touchend", (e) => {
  pointerDown = false;

  console.log(deltaX);

  if (deltaX < 1) {
    cardsContainer.scrollTo({
      left: window.innerWidth * n + window.innerWidth,
      behavior: "smooth",
    });

    if (n < questions.length - 1) {
      n++;
    }
  }

  if (deltaX > 1) {
    cardsContainer.scrollTo({
      left: window.innerWidth * n - window.innerWidth,
      behavior: "smooth",
    });
    if (n > 0) {
      n--;
    }
  }
});

window.addEventListener("touchmove", (e) => {
  if (!pointerDown) return;

  deltaX = e.touches[0].screenX - startX;
  console.log(n);

  cardsContainer.scrollTo({ left: window.innerWidth * n - deltaX });
});

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.register(new URL("/serviceworker.ts", import.meta.url), {
    type: "module",
  });
}
